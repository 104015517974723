import { ProductCardType } from '@/constants'
import { labelPositionProductCard } from '@/constants/positions'
import { useNotification } from '@/containers'
import type { Product } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Link from 'next/link'
import ImageOptimize from '../ImageOptimize'
import Price from '../Price'
import ProgressBar from '../ProgressBar'
// import PolicyCard from './PolicyCard'
export interface ProductProps {
  product: Product
  type?: string
  layout?: number
  index?: number
  className?: string
}

const ProductCardFS = ({ className, product, type, layout }: ProductProps) => {
  const percent = Math.ceil(100 - (product?.finalPrice / product?.price) * 100)
  const { systemConfig } = useNotification()

  const linkToDetail =
    '/' + getTranslate(product?.slugs?.value) + product?.slugs?.postfix

  return (
    <Link
      href={linkToDetail}
      className={`productCard relative ${className ? className : ''} ${
        type ? type : ''
      } ${layout ? 'l' + layout : ''} ${
        type == ProductCardType.TRENDING ? 'rounded-sm' : ''
      }`}
    >
      <div
        className={`itemProducts relative grid grid-cols-2 items-center gap-2 overflow-hidden py-3`}
      >
        <div
          className={`image-thumb relative block w-full ${
            layout == 1 ? 'order-last' : ''
          }`}
        >
          <span className='absolute bottom-0 left-5 z-10 flex h-[48px] w-[48px] items-center justify-center rounded-full bg-primary-second text-base font-bold text-white'>
            {'-' + percent + '%'}
          </span>
          {product?.labels &&
            product?.labels?.map((item, index) => {
              return (
                <>
                  {item?.image && (
                    <span
                      className={`${labelPositionProductCard.get(
                        item?.position
                      )} label hiddenScrollBar absolute flex h-auto w-full flex-nowrap gap-x-2 overflow-x-auto overflow-y-hidden`}
                      key={index}
                      style={{ zIndex: 50 + index }}
                    >
                      <ImageOptimize
                        src={item?.image}
                        alt={getTranslate(item?.name)}
                        width={200}
                        height={48}
                        loading='lazy'
                        className={`h-auto w-full`}
                      />
                    </span>
                  )}
                </>
              )
            })}
          <div className='relative block w-full overflow-hidden pt-[100%] focus:border-0 focus:outline-0'>
            <ImageOptimize
              className='productImage absolute left-0 top-0 aspect-square h-full w-full object-cover'
              src={
                product?.thumbnail
                  ? product?.thumbnail
                  : systemConfig?.imagePlaceholder ||
                    process.env.NEXT_PUBLIC_IMAGE_PLACEHOLDER ||
                    ''
              }
              alt={getTranslate(product?.name)}
              width={300}
              height={300}
              loading='lazy'
              thumbnail
            />
          </div>
        </div>
        <div className={`w-full text-left`}>
          <div
            className={`${
              type == ProductCardType.RELATED ? '' : ''
            } mb-1 line-clamp-2 block h-[40px] overflow-hidden text-ellipsis text-titleProduct text-dark-900 focus:border-0 focus:outline-none`}
          >
            {getTranslate(product?.name)}
          </div>
          <Price
            type={ProductCardType.PRODUCT_CARD}
            classes='!text-sm'
            classesSale='text-primary-second !text-[28px] leading-6'
            link={linkToDetail}
            originPrice={product?.price}
            finalPrice={product?.finalPrice}
          />
          <div
            className={`mt-3 w-[85%] pt-0.5 ${
              !product?.flashSale && type === 'list' ? 'hidden' : ''
            }`}
          >
            {product?.flashSale && (
              <div>
                <ProgressBar
                  fsType={product.type}
                  flashSale={product?.flashSale}
                  type={ProductCardType.PRODUCT_CARD}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ProductCardFS
